import { stringify } from "query-string";
import {
  CREATE,
  DELETE,
  // DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY
} from "react-admin";
import apiUrl from "./apiUrl";

const contactsSeparator = "-";
const schedulesSeparator = "-";

async function uploadFile(data) {
  const formData = new FormData();
  formData.append("file", data);
  const options = {
    body: formData,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken")
    },
    method: "POST"
  };
  const response = await fetch(
    `${apiUrl}/api/services/app/Files/UploadFile`,
    options
  );
  return response.ok ? await response.json() : null;
}

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
export default async (type, resource, params) => {
  let url = "";
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
      "Content-Type": "application/json"
    }
  };
  switch (type) {
    case "APPROVE_TICKET":
      options.method = "POST";
      url = `${apiUrl}/api/services/app/Tickets/Approve?id=${params.id}`;
      break;
    case CREATE:
      options.method = "POST";
      switch (resource) {
        case "articles": {
          const articleData = {
            ...params.data,
            ...{ images: [], isHtml: true }
          };
          // Upload images
          if (params.data.mainImage) {
            const r = await uploadFile(params.data.mainImage.rawFile);
            if (r && r.result && r.result.name) {
              articleData.mainImage = r.result.name;
              articleData.images.push(r.result.name);
            }
          }
          if (params.data.images) {
            const length = params.data.images.length;
            for (let i = 0; i < length; i++) {
              const r = await uploadFile(params.data.images[i].rawFile);
              if (r && r.result && r.result.name) {
                articleData.images.push(r.result.name);
              }
            }
          }
          // Save article
          url = `${apiUrl}/api/services/app/Article/Create`;
          options.body = JSON.stringify(articleData);
          break;
        }
        case "contacts": {
          delete params.data.pointId;
          url = `${apiUrl}/api/services/app/ServicePointContacts/Create`;
          options.body = JSON.stringify(params.data);
          break;
        }
        case "faq": {
          url = `${apiUrl}/api/services/app/Faq/Create`;
          params.data.answerIsHtml = true;
          params.data.questionIsHtml = true;
          options.body = JSON.stringify(params.data);
          break;
        }
        case "roles": {
          url = `${apiUrl}/api/services/app/Role/Create`;
          params.data.normalizedName = params.data.name;
          options.body = JSON.stringify(params.data);
          break;
        }
        case "servicePoints": {
          url = `${apiUrl}/api/services/app/ServicePoints/Create`;
          params.data.address.countryISO3 = "RUS";
          options.body = JSON.stringify(params.data);
          if (params.data.tags) {
            const tags = params.data.tags.map(v => {
              return { id: v };
            });
            options.body = JSON.stringify({ ...params.data, ...{ tags } });
          }
          break;
        }
        case "schedules": {
          delete params.data.pointId;
          if (params.data.days) {
            const days = [];
            params.data.days.forEach((v, i) => {
              const day = {};
              if (v && v.start && v.end) {
                day.dayOfWeek = i;
                day.start = v.start;
                day.end = v.end;
              }
              if (v && v.breakStart && v.breakEnd) {
                day.dayOfWeek = i;
                day.breakStart = v.breakStart;
                day.breakEnd = v.breakEnd;
              }
              if (day.dayOfWeek >= 0) {
                days.push(day);
              }
            });
            params.data.days = days;
          }
          url = `${apiUrl}/api/services/app/ServicePointSchedules/Create`;
          options.body = JSON.stringify(params.data);
          break;
        }
        case "users": {
          url = `${apiUrl}/api/services/app/User/Create`;
          options.body = JSON.stringify(params.data);
          break;
        }
        case "vacancy": {
          url = `${apiUrl}/api/services/app/Vacancy/Create`;
          params.data.descriptionIsHtml = true;
          options.body = JSON.stringify(params.data);
          break;
        }
        default: {
          url = `${apiUrl}/${resource}`;
          options.body = JSON.stringify(params.data);
          break;
        }
      }
      break;
    case DELETE: {
      options.method = "DELETE";
      switch (resource) {
        case "articles": {
          url = `${apiUrl}/api/services/app/Article/Delete/?${stringify({
            id: params.id
          })}`;
          break;
        }
        case "contacts": {
          const ids = params.id.split(contactsSeparator);
          url = `${apiUrl}/api/services/app/ServicePointContacts/Delete?${stringify(
            {
              id: ids[0],
              servicePointId: ids[1]
            }
          )}`;
          break;
        }
        case "faq": {
          url = `${apiUrl}/api/services/app/Faq/Delete/?${stringify({
            id: params.id
          })}`;
          break;
        }
        case "roles": {
          url = `${apiUrl}/api/services/app/Role/Delete/?${stringify({
            id: params.id
          })}`;
          break;
        }
        case "servicePoints": {
          url = `${apiUrl}/api/services/app/ServicePoints/Delete/?${stringify({
            id: params.id
          })}`;
          break;
        }
        case "schedules": {
          const ids = params.id.split(schedulesSeparator);
          url = `${apiUrl}/api/services/app/ServicePointSchedules/Delete?${stringify(
            {
              id: ids[0],
              servicePointId: ids[1]
            }
          )}`;
          break;
        }
        case "users": {
          url = `${apiUrl}/api/services/app/User/Delete/?${stringify({
            id: params.id
          })}`;
          break;
        }
        case "vacancy": {
          url = `${apiUrl}/api/services/app/Vacancy/Delete/?${stringify({
            id: params.id
          })}`;
          break;
        }
        default: {
          url = `${apiUrl}/${resource}/${params.id}`;
          break;
        }
      }
      break;
    }
    // case DELETE_MANY: {
    //   const query = {
    //     filter: JSON.stringify({ id: params.ids })
    //   };
    //   url = `${apiUrl}/${resource}?${stringify(query)}`;
    //   options.method = "DELETE";
    //   break;
    // }
    case GET_LIST: {
      switch (resource) {
        case "articles": {
          const { page, perPage } = params.pagination;
          const query = {
            isPublished: false,
            MaxResultCount: perPage,
            SkipCount: (page - 1) * perPage
          };
          url = `${apiUrl}/api/services/app/Article/GetAll?${stringify(query)}`;
          break;
        }
        case "measurements": {
          const { page, perPage } = params.pagination;
          const query = {
            MaxResultCount: perPage,
            SkipCount: (page - 1) * perPage
          };
          url = `${apiUrl}/api/services/app/Measurement/GetAll?${stringify(
            query
          )}`;
          break;
        }
        case "payments": {
          const { page, perPage } = params.pagination;
          const query = {
            MaxResultCount: perPage,
            SkipCount: (page - 1) * perPage
          };
          url = `${apiUrl}/api/services/app/Payment/GetAll?${stringify(query)}`;
          break;
        }
        case "payment-accounts": {
          const { page, perPage } = params.pagination;
          const query = {
            MaxResultCount: perPage,
            SkipCount: (page - 1) * perPage
          };
          url = `${apiUrl}/api/services/app/Payment/GetAllAccounts?${stringify(
            query
          )}`;
          break;
        }
        case "city": {
          url = `${apiUrl}/api/services/app/Dictionaries/Get?Id=2147483647`;
          break;
        }
        case "faq": {
          const { page, perPage } = params.pagination;
          const query = {
            MaxResultCount: perPage,
            SkipCount: (page - 1) * perPage
          };
          url = `${apiUrl}/api/services/app/Faq/GetAll?${stringify(query)}`;
          break;
        }
        case "permissions": {
          url = `${apiUrl}/api/services/app/Role/GetAllPermissions`;
          break;
        }
        case "roles": {
          const { page, perPage } = params.pagination;
          const query = {
            MaxResultCount: perPage,
            SkipCount: (page - 1) * perPage
          };
          url = `${apiUrl}/api/services/app/Role/GetAll?${stringify(query)}`;
          break;
        }
        case "rolesList": {
          url = `${apiUrl}/api/services/app/User/GetRoles`;
          break;
        }
        case "servicePoints": {
          const { page, perPage } = params.pagination;
          const query = {
            MaxResultCount: perPage,
            SkipCount: (page - 1) * perPage
          };
          url = `${apiUrl}/api/services/app/ServicePoints/GetAll?${stringify(
            query
          )}`;
          break;
        }
        case "servicePointsList": {
          const { page, perPage } = params.pagination;
          const query = {
            MaxResultCount: perPage,
            SkipCount: (page - 1) * perPage
          };
          url = `${apiUrl}/api/services/app/ServicePoints/GetAll?${stringify(
            query
          )}`;
          break;
        }
        case "tags": {
          url = `${apiUrl}/api/services/app/Tag/GetAll`;
          break;
        }
        case "tickets": {
          console.log("TCL: params", params);
          const { page, perPage } = params.pagination;
          const query = {
            MaxResultCount: perPage,
            SkipCount: (page - 1) * perPage,
            Sorting: "CreationTime DESC",
            ...params.filter
          };
          url = `${apiUrl}/api/services/app/Tickets/GetAll?${stringify(query)}`;
          break;
        }
        case "users": {
          const { page, perPage } = params.pagination;
          const query = {
            MaxResultCount: perPage,
            SkipCount: (page - 1) * perPage
          };
          url = `${apiUrl}/api/services/app/User/GetAll?${stringify(query)}`;
          break;
        }
        case "vacancy": {
          const { page, perPage } = params.pagination;
          const query = {
            MaxResultCount: perPage,
            SkipCount: (page - 1) * perPage
          };
          url = `${apiUrl}/api/services/app/Vacancy/GetAll?${stringify(query)}`;
          break;
        }
        case "vacancyType": {
          url = `${apiUrl}/api/services/app/Dictionaries/Get?Id=2147483646`;
          break;
        }
        default: {
          const { page, perPage } = params.pagination;
          const { field, order } = params.sort;
          const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter)
          };
          url = `${apiUrl}/${resource}?${stringify(query)}`;
          break;
        }
      }
      break;
    }
    case GET_ONE:
      switch (resource) {
        case "articles": {
          url = `${apiUrl}/api/services/app/Article/Get?${stringify({
            id: params.id
          })}`;
          break;
        }
        case "contacts": {
          const ids = params.id.split(contactsSeparator);
          url = `${apiUrl}/api/services/app/ServicePointContacts/Get?${stringify(
            {
              id: ids[0],
              servicePointId: ids[1]
            }
          )}`;
          break;
        }
        case "faq": {
          url = `${apiUrl}/api/services/app/Faq/Get?${stringify({
            id: params.id
          })}`;
          break;
        }
        case "roles": {
          url = `${apiUrl}/api/services/app/Role/Get?${stringify({
            id: params.id
          })}`;
          break;
        }
        case "servicePoints": {
          url = `${apiUrl}/api/services/app/ServicePoints/Get?${stringify({
            id: params.id
          })}`;
          break;
        }
        case "schedules": {
          const ids = params.id.split(schedulesSeparator);
          url = `${apiUrl}/api/services/app/ServicePointSchedules/Get?${stringify(
            {
              id: ids[0],
              servicePointId: ids[1]
            }
          )}`;
          break;
        }
        case "users": {
          url = `${apiUrl}/api/services/app/User/Get?${stringify({
            id: params.id
          })}`;
          break;
        }
        case "vacancy": {
          url = `${apiUrl}/api/services/app/Vacancy/Get?${stringify({
            id: params.id
          })}`;
          break;
        }
        case "tickets": {
          url = `${apiUrl}/api/services/app/Tickets/Get?${stringify({
            id: params.id
          })}`;
          break;
        }
        default: {
          url = `${apiUrl}/${resource}/${params.id}`;
          break;
        }
      }
      break;
    case GET_MANY: {
      switch (resource) {
        case "city": {
          url = `${apiUrl}/api/services/app/Dictionaries/Get?Id=2147483647`;
          break;
        }
        case "permissions": {
          url = `${apiUrl}/api/services/app/Role/GetAllPermissions`;
          break;
        }
        case "rolesList": {
          url = `${apiUrl}/api/services/app/User/GetRoles`;
          break;
        }
        case "servicePointsList": {
          url = `${apiUrl}/api/services/app/ServicePoints/GetAll`;
          break;
        }
        case "tags": {
          url = `${apiUrl}/api/services/app/Tag/GetAll`;
          break;
        }
        case "vacancyType": {
          url = `${apiUrl}/api/services/app/Dictionaries/Get?Id=2147483646`;
          break;
        }
        default: {
          const query = {
            filter: JSON.stringify({ id: params.ids })
          };
          url = `${apiUrl}/${resource}?${stringify(query)}`;
          break;
        }
      }
      break;
    }
    case GET_MANY_REFERENCE: {
      switch (resource) {
        case "contacts": {
          url = `${apiUrl}/api/services/app/ServicePointContacts/GetAll?${stringify(
            {
              servicePointId: params.id
            }
          )}`;
          break;
        }
        case "schedules": {
          url = `${apiUrl}/api/services/app/ServicePointSchedules/GetAll?${stringify(
            {
              servicePointId: params.id
            }
          )}`;
          break;
        }
        default: {
          const { page, perPage } = params.pagination;
          const { field, order } = params.sort;
          const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
              ...params.filter,
              [params.target]: params.id
            })
          };
          url = `${apiUrl}/${resource}?${stringify(query)}`;
          break;
        }
      }
      break;
    }
    case UPDATE:
      options.method = "PUT";
      switch (resource) {
        case "articles": {
          const articleData = {
            ...params.data,
            ...{ images: [], tagIds: params.data.tags }
          };
          // Upload images
          if (params.data.mainImage) {
            if (params.data.mainImage.rawFile) {
              const r = await uploadFile(params.data.mainImage.rawFile);
              if (r && r.result && r.result.name) {
                articleData.mainImage = r.result.name;
                articleData.images.push(r.result.name);
              }
            } else {
              articleData.mainImage = params.data.mainImage.src;
              articleData.images.push(params.data.mainImage.src);
            }
          }
          if (params.data.images) {
            const length = params.data.images.length;
            for (let i = 0; i < length; i++) {
              if (params.data.images[i].rawFile) {
                const r = await uploadFile(params.data.images[i].rawFile);
                if (r && r.result && r.result.name) {
                  articleData.images.push(r.result.name);
                }
              } else {
                articleData.images.push(params.data.images[i].src);
              }
            }
          }
          // Save article
          url = `${apiUrl}/api/services/app/Article/Update`;
          options.body = JSON.stringify(articleData);
          break;
        }
        case "contacts": {
          delete params.data.pointId;
          const ids = params.data.id.split(contactsSeparator);
          url = `${apiUrl}/api/services/app/ServicePointContacts/Update`;
          params.data.id = ids[0];
          options.body = JSON.stringify(params.data);
          break;
        }
        case "faq": {
          url = `${apiUrl}/api/services/app/Faq/Update`;
          params.data.answerIsHtml = true;
          params.data.questionIsHtml = true;
          options.body = JSON.stringify(params.data);
          break;
        }
        case "profile": {
          url = `${apiUrl}/api/services/app/User/ChangePassword`;
          options.body = JSON.stringify(params.data);
          options.method = "POST";
          break;
        }
        case "roles": {
          url = `${apiUrl}/api/services/app/Role/Update`;
          params.data.normalizedName = params.data.name;
          options.body = JSON.stringify(params.data);
          break;
        }
        case "servicePoints": {
          url = `${apiUrl}/api/services/app/ServicePoints/Update`;
          params.data.address.cityId = params.data.address.city.id;
          options.body = JSON.stringify(params.data);
          if (params.data.tags) {
            const tagIds = params.data.tags.map(v => {
              return v;
            });
            options.body = JSON.stringify({ ...params.data, ...{ tagIds } });
          }
          break;
        }
        case "schedules": {
          delete params.data.pointId;
          if (params.data.days) {
            const days = [];
            params.data.days.forEach((v, i) => {
              const day = {};
              if (v && v.start && v.end) {
                day.dayOfWeek = i;
                day.start = v.start;
                day.end = v.end;
              }
              if (v && v.breakStart && v.breakEnd) {
                day.dayOfWeek = i;
                day.breakStart = v.breakStart;
                day.breakEnd = v.breakEnd;
              }
              if (day.dayOfWeek >= 0) {
                days.push(day);
              }
            });
            params.data.days = days;
          }
          const ids = params.data.id.split(contactsSeparator);
          url = `${apiUrl}/api/services/app/ServicePointSchedules/Update`;
          params.data.id = ids[0];
          options.body = JSON.stringify(params.data);
          break;
        }
        case "userPassword": {
          url = `${apiUrl}/api/services/app/User/ResetPassword`;
          options.body = JSON.stringify(params.data);
          options.method = "POST";
          break;
        }
        case "users": {
          url = `${apiUrl}/api/services/app/User/Update`;
          params.data.fullName = params.data.name + " " + params.data.surname;
          options.body = JSON.stringify(params.data);
          break;
        }
        case "vacancy": {
          url = `${apiUrl}/api/services/app/Vacancy/Update`;
          const data = {
            id: params.data.id,
            name: params.data.name,
            description: params.data.description,
            descriptionIsHtml: false,
            typeId: params.data.type.id,
            workExperience: params.data.workExperience,
            salary: params.data.salary,
            currency: "RUB",
            servicePointId: params.data.servicePoint.id
          };
          options.body = JSON.stringify(data);
          break;
        }
        default: {
          url = `${apiUrl}/${resource}/${params.id}`;
          options.body = JSON.stringify(params.data);
          break;
        }
      }
      break;
    case UPDATE_MANY: {
      const query = {
        filter: JSON.stringify({ id: params.ids })
      };
      url = `${apiUrl}/${resource}?${stringify(query)}`;
      options.method = "PATCH";
      options.body = JSON.stringify(params.data);
      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  if (resource === "files") {
    return {
      data: [],
      total: 0
    };
  } else if (resource === "profile" && type === GET_ONE) {
    return {
      data: {
        currentPassword: "",
        id: "profile",
        newPassword: "",
        userId: localStorage.getItem("userId")
          ? parseInt(localStorage.getItem("userId"), 10)
          : 0
      }
    };
  }

  let headers;
  let status;
  return fetch(url, options)
    .then(res => {
      headers = res.headers;
      status = res.status;
      return res.json();
    })
    .then(json => {
      if (
        (json.result && json.result.unAuthorizedRequest) ||
        status === 401 ||
        status === 403
      ) {
        // localStorage.removeItem("token");
        // localStorage.removeItem("accessToken");
        // localStorage.removeItem("userId");
        // localStorage.removeItem("grantedPermissions");
        // window.location.reload(false);
        throw new Error(json.error.message);
      }
      if (json.error) {
        throw new Error(json.error.message);
      }
      switch (type) {
        case CREATE: {
          return { data: json.result || {} };
        }
        case DELETE: {
          return { data: { id: params.id } };
        }
        case GET_LIST: {
          const data = json.result.items || [];
          if (resource === "permissions") {
            data.forEach(v => {
              v.id = v.name;
            });
          } else if (resource === "rolesList") {
            data.forEach(v => {
              v.id = v.name.toUpperCase();
            });
          } else if (resource === "servicePointsList") {
            data.forEach(v => {
              v.label =
                v.name || v.address.city.value + ", " + v.address.address1;
            });
          }
          return {
            data,
            total: json.result.totalCount || data.length
          };
        }
        case GET_MANY: {
          let data = [];
          if (resource === "permissions") {
            json.result.items.forEach(v => {
              if (params.ids.indexOf(v.name) !== -1) {
                v.id = v.name;
                data.push(v);
              }
            });
          } else if (resource === "rolesList") {
            json.result.items.forEach(v => {
              if (params.ids.indexOf(v.name) !== -1) {
                v.id = v.name.toUpperCase();
                data.push(v);
              }
            });
          } else if (resource === "servicePointsList") {
            json.result.items.forEach(v => {
              if (params.ids.indexOf(v.name) !== -1) {
                v.name = v.address.city.value + ", " + v.address.address1;
                data.push(v);
              }
            });
          } else if (json.result.items) {
            data = json.result.items;
          }
          return {
            data
          };
        }
        case GET_MANY_REFERENCE: {
          const data = json.result.items || [];
          switch (resource) {
            case "contacts": {
              data.forEach(v => {
                v.id = v.id + contactsSeparator + params.id;
              });
              break;
            }
            case "schedules": {
              data.forEach(v => {
                v.id = v.id + schedulesSeparator + params.id;
              });
              break;
            }
            default: {
              if (!headers.has("content-range")) {
                throw new Error(
                  "The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?"
                );
              }
              return {
                data: json,
                total: parseInt(
                  headers
                    .get("content-range")
                    .split("/")
                    .pop(),
                  10
                )
              };
            }
          }
          return {
            data,
            total: json.result.totalCount || data.length
          };
        }
        case GET_ONE: {
          let data = json.result || {};
          if (resource === "articles") {
            if (data.tags) {
              data.tags = data.tags.map(v => v.id);
            }
            if (data.images) {
              data.images = data.images
                .filter(v => !data.mainImage || v !== data.mainImage)
                .map(v => {
                  return {
                    src: v,
                    title: ""
                  };
                });
            }
            if (data.mainImage) {
              data.mainImage = {
                src: data.mainImage,
                title: ""
              };
            }
          } else if (resource === "contacts") {
            data.id = params.id;
            const ids = params.id.split(contactsSeparator);
            data.servicePointId = parseInt(ids[1], 10);
          } else if (resource === "servicePoints") {
            if (data.tags) {
              data.tags = data.tags.map(v => v.id);
            }
          } else if (resource === "schedules") {
            data.id = params.id;
            const ids = params.id.split(schedulesSeparator);
            data.servicePointId = parseInt(ids[1], 10);
            const days = [];
            data.days.forEach(v => {
              days[v.dayOfWeek] = v;
            });
            data.days = days;
          }
          return {
            data
          };
        }
        case UPDATE: {
          return {
            data: json.result || {}
          };
        }
        default: {
          return { data: json };
        }
      }
    });
};
