import React from "react";
import {
  Edit,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput
} from "react-admin";

import RichTextInput from "ra-input-rich-text";

export const VacancyEdit = props => {
  return (
    <Edit title="Редактирование" {...props}>
      <SimpleForm>
        <ReferenceInput
          label="Сервисный пункт"
          source="servicePoint.id"
          reference="servicePointsList"
        >
          <SelectInput optionText="label" />
        </ReferenceInput>
        <TextInput source="name" label="Название" />
        <ReferenceInput label="Тип" source="type.id" reference="vacancyType">
          <SelectInput optionText="value" />
        </ReferenceInput>
        <NumberInput source="salary" label="Зарплата" />
        <TextInput source="workExperience" label="Опыт работы" />
        <RichTextInput
          source="description"
          label="Описание"
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
};
