export const renderType = (record, source) => {
  const type = record[source];
  switch (type) {
    default:
    case 0:
      return "Поддержка";
    case 1:
      return "Заявка от провайдера";
    case 2:
      return "Отклик на вакансию";
    case 3:
      return "Смена количества проживающих ТКО";
  }
};
