import React from "react";
import {
  CardActions,
  CreateButton,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField
} from "react-admin";

const FAQActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);

export const FAQList = props => (
  <List
    {...props}
    title="FAQ"
    actions={<FAQActions />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="question" label="Вопрос" />
      <TextField source="answer" label="Ответ" />
      <EditButton label="Редактировать" />
      <DeleteButton label="Удалить" />
    </Datagrid>
  </List>
);
