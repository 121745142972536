import React from "react";
import {
  BooleanField,
  CardActions,
  CreateButton,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField
} from "react-admin";

import { TagsField } from "./tags";

const ArticlesActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);

export const ArticlesList = props => (
  <List
    {...props}
    title="Статьи / новости"
    actions={<ArticlesActions />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="title" label="Заголовок" />
      <TextField source="subtitle" label="Подзаголовок" />
      <TagsField label="Теги" />
      <BooleanField source="isPublished" label="Опубликована" />
      <EditButton label="Редактировать" />
      <DeleteButton label="Удалить" />
    </Datagrid>
  </List>
);
