import React from "react";

export const TagsField = ({ record }) => {
  return record.tags ? (
    <ul>
      {record.tags.map((item, i) => (
        <li key={i}>{item.name}</li>
      ))}
    </ul>
  ) : null;
};
TagsField.defaultProps = { addLabel: true };
