import React from "react";
import {
  Edit,
  LongTextInput,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SimpleForm,
  TextInput
} from "react-admin";

export const RoleEdit = props => (
  <Edit title="Редактирование" {...props}>
    <SimpleForm>
      <TextInput source="name" label="Код" validate={required()} />
      <TextInput source="displayName" label="Название" validate={required()} />
      <LongTextInput
        source="description"
        label="Описание"
        validate={required()}
      />
      <ReferenceArrayInput
        source="permissions"
        reference="permissions"
        label="Разрешения"
        validate={required()}
      >
        <SelectArrayInput optionText="displayName" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);
