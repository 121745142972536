import React from "react";
import {
  Edit,
  LongTextInput,
  NumberInput,
  required,
  SimpleForm
} from "react-admin";

import RichTextInput from "ra-input-rich-text";

export const FAQEdit = props => {
  return (
    <Edit title="Редактирование" {...props}>
      <SimpleForm>
        {props.questionIsHtml || true ? (
          <RichTextInput
            source="question"
            label="Вопрос"
            validate={required()}
          />
        ) : (
          <LongTextInput
            source="question"
            label="Вопрос"
            validate={required()}
          />
        )}
        {props.answerIsHtml || true ? (
          <RichTextInput source="answer" label="Ответ" validate={required()} />
        ) : (
          <LongTextInput source="answer" label="Ответ" validate={required()} />
        )}
        <NumberInput source="order" label="Порядковый номер" />
      </SimpleForm>
    </Edit>
  );
};
