import React from "react";
import {
  BooleanInput,
  Edit,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SimpleForm,
  TextInput
} from "react-admin";

import RichTextInput from "ra-input-rich-text";

export const ArticlesEdit = props => (
  <Edit title="Редактирование" {...props}>
    <SimpleForm>
      <TextInput source="title" label="Заголовок" validate={required()} />
      <TextInput source="subtitle" label="Подзаголовок" />
      <TextInput source="url" label="Внешняя ссылка" />
      <RichTextInput source="text" label="Основной текст новости" />
      <ReferenceArrayInput source="tags" reference="tags" label="Теги">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <BooleanInput
        label="Опубликована"
        source="isPublished"
        defaultValue={false}
      />
      <ImageInput
        source="mainImage"
        label="Главное изображение"
        accept="image/*"
        validate={required()}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput
        source="images"
        label="Дополнительные изображения"
        accept="image/*"
        multiple={true}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);
