import React from "react";
import { TextInput } from "react-admin";

const timeValidation = (value, allValues) => {
  const isRequired = false;
  let isEmptyValue = !value;
  if (!isEmptyValue) {
    isEmptyValue = value === "";
  }
  const validationMask = /^[0-9.:]+$/;
  let isValid = (!isRequired && isEmptyValue) || validationMask.test(value);
  if (!isValid) {
    return "Проверьте введённое время";
  }
  return;
};

const labelStyle = {
  verticalAlign: "bottom",
  lineHeight: "50px",
  paddingRight: "20px"
};

export const Daysgrid = () => (
  <table>
    <thead>
      <tr style={{ height: "80px", verticalAlign: "bottom" }}>
        <td />
        <td colSpan="2">Время работы</td>
        <td />
        <td colSpan="2">Обед</td>
      </tr>
    </thead>
    <tbody>
      {/* {React.Children.map(children, field => (
        <tr>
          <td key={`${id}-${field.props.source}`}>
            {React.cloneElement(field, {
              record,
              basePath,
              resource
            })}
          </td>
        </tr>
      ))} */}
      {["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"].map((v, i) => (
        <tr key={i}>
          <td style={labelStyle}>{v}</td>
          <td>
            <TextInput
              source={"days[" + i + "].start"}
              label="с"
              validate={timeValidation}
            />
          </td>
          <td>
            <TextInput
              source={"days[" + i + "].end"}
              label="до"
              validate={timeValidation}
            />
          </td>
          <td style={labelStyle}></td>
          <td>
            <TextInput
              source={"days[" + i + "].breakStart"}
              label="с"
              validate={timeValidation}
            />
          </td>
          <td>
            <TextInput
              source={"days[" + i + "].breakEnd"}
              label="до"
              validate={timeValidation}
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);
