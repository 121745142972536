import React from "react";
import {
  BooleanInput,
  Edit,
  email,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SimpleForm,
  TextInput
} from "react-admin";

export const UserEdit = props => (
  <Edit title="Редактирование" {...props}>
    <SimpleForm>
      <TextInput source="userName" label="Логин" validate={required()} />
      <TextInput source="name" label="Имя" validate={required()} />
      <TextInput source="surname" label="Фамилия" validate={required()} />
      <TextInput
        source="emailAddress"
        label="Email"
        validate={[required(), email()]}
      />
      <ReferenceArrayInput
        source="roleNames"
        reference="rolesList"
        label="Роли"
        validate={required()}
      >
        <SelectArrayInput optionText="displayName" />
      </ReferenceArrayInput>
      <BooleanInput label="Активен" source="isActive" defaultValue />
    </SimpleForm>
  </Edit>
);
