import React from "react";
import { Datagrid, List, TextField } from "react-admin";

const PaymentsList = props => (
  <List {...props} title="Платежи" bulkActionButtons={false}>
    <Datagrid>
      <TextField source="clientId" label="Номер клиента" sortable={false} />
      <TextField source="invoiceId" label="Номер квитанции" sortable={false} />
      <TextField source="phoneNumber" label="Телефон" sortable={false} />
      <TextField source="email" label="Электронная почта" sortable={false} />
      <TextField source="customerName" label="Имя клиента" sortable={false} />
      <TextField source="address" label="Адрес" sortable={false} />
      <TextField source="amount" label="Сумма" sortable={false} />
    </Datagrid>
  </List>
);

export default PaymentsList;
