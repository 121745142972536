import React from "react";
import {
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput
} from "react-admin";

import RichTextInput from "ra-input-rich-text";

export const VacancyCreate = props => (
  <Create title="Новый вопрос-ответ" {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput
        label="Сервисный пункт"
        source="servicePointId"
        reference="servicePointsList"
      >
        <SelectInput optionText="label" />
      </ReferenceInput>
      <TextInput source="name" label="Название" />
      <ReferenceInput label="Тип" source="typeId" reference="vacancyType">
        <SelectInput optionText="value" />
      </ReferenceInput>
      <NumberInput source="salary" label="Зарплата" />
      <TextInput source="workExperience" label="Опыт работы" />
      <RichTextInput
        source="description"
        label="Описание"
        validate={required()}
      />
    </SimpleForm>
  </Create>
);
