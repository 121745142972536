import React from "react";

export const RolesField = ({ record }) => {
  return record.roleNames ? (
    <ul>
      {record.roleNames.map((item, i) => (
        <li key={i}>{item}</li>
      ))}
    </ul>
  ) : null;
};
RolesField.defaultProps = { addLabel: true };
