import React from "react";
import {
  BooleanField,
  CardActions,
  CreateButton,
  Datagrid,
  DatagridBody,
  DeleteButton,
  EditButton,
  List,
  TextField
} from "react-admin";

import { TableCell, TableRow } from "@material-ui/core";

import UsersPassword from "./password";
import { RolesField } from "./roles";

const MyDatagridRow = ({ record, resource, id, children, basePath }) => {
  const userId = localStorage.getItem("userId")
    ? parseInt(localStorage.getItem("userId"), 10)
    : 0;
  return (
    <TableRow key={id}>
      {React.Children.map(children, field => (
        <TableCell key={`${id}-${field.props.source}`}>
          {(field.props.source || (record.id !== userId && !record.isStatic)) &&
            React.cloneElement(field, {
              record,
              basePath,
              resource
            })}
          {field.props.label === "Редактировать" &&
          record.id !== userId &&
          !record.isStatic ? (
            <UsersPassword user={record} />
          ) : null}
        </TableCell>
      ))}
    </TableRow>
  );
};

const MyDatagridBody = props => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;

const UsersActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);

export const UsersList = props => (
  <List
    {...props}
    title="Пользователи"
    actions={<UsersActions />}
    bulkActionButtons={false}
  >
    <MyDatagrid>
      <TextField source="userName" label="Логин" />
      <TextField source="name" label="Имя" />
      <TextField source="surname" label="Фамилия" />
      <TextField source="emailAddress" label="Email" />
      <RolesField label="Роли" />
      <BooleanField source="isActive" label="Активен" />
      <EditButton label="Редактировать" />
      <DeleteButton label="Удалить" />
    </MyDatagrid>
  </List>
);
