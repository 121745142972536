import React from "react";
import {
  Edit,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar
} from "react-admin";

const ProfileCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ProfileEdit = ({ staticContext, ...props }) => {
  return (
    <Edit
      id="profile"
      resource="profile"
      basePath="/profile"
      title="Изменить пароль"
      redirect={false}
      undoable={false}
      {...props}
    >
      <SimpleForm toolbar={<ProfileCreateToolbar />}>
        <TextInput
          label="Текущий пароль"
          source="currentPassword"
          type="password"
          validate={required()}
        />
        <TextInput
          label="Новый пароль"
          source="newPassword"
          type="password"
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ProfileEdit;
