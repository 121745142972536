import React from "react";
import { Admin, Resource } from "react-admin";
import { Route } from "react-router-dom";
import russianMessages from "ra-language-russian";

import FileIcon from "@material-ui/icons/FileDownload";
import LocationIcon from "@material-ui/icons/LocationCity";
import NoteIcon from "@material-ui/icons/Note";
import PeopleIcon from "@material-ui/icons/People";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ReceiptIcon from "@material-ui/icons/Receipt";
import WorkIcon from "@material-ui/icons/Work";

import { ArticlesCreate } from "./articles/create";
import { ArticlesEdit } from "./articles/edit";
import { ArticlesList } from "./articles/list";
import { FAQCreate } from "./faq/create";
import { FAQEdit } from "./faq/edit";
import { FAQList } from "./faq/list";
import { FilesList } from "./files/list";
import MainLayout from "./layout/main";
import ProfileEdit from "./profile/edit";
import authProvider from "./providers/auth_provider";
import dataProvider from "./providers/data_provider";
import { RoleCreate } from "./roles/create";
import { RoleEdit } from "./roles/edit";
import { RolesList } from "./roles/list";
import { ContactsCreate } from "./service_points/contacts/create";
import { ContactsEdit } from "./service_points/contacts/edit";
import { ServicePointsCreate } from "./service_points/create";
import ServicePointsEdit from "./service_points/edit";
import { ServicePointsList } from "./service_points/list";
import { SchedulesCreate } from "./service_points/schedules/create";
import { SchedulesEdit } from "./service_points/schedules/edit";
import { TicketsList } from "./tickets/list";
import { TicketsShow } from "./tickets/show";
import { UserCreate } from "./users/create";
import { UserEdit } from "./users/edit";
import { UsersList } from "./users/list";
import { VacancyCreate } from "./vacancy/create";
import { VacancyEdit } from "./vacancy/edit";
import { VacancyList } from "./vacancy/list";
import Measurements from "./measurements";
import Payments from "./payments";
import PaymentAccounts from "./payment-accounts";

const messages = {
  ru: russianMessages
};
const i18nProvider = locale => messages[locale];

// import jsonServerProvider from "ra-data-json-server";
// const dataProvider = jsonServerProvider("http://jsonplaceholder.typicode.com");

let grantedPermissions = localStorage.getItem("grantedPermissions");
try {
  grantedPermissions = JSON.parse(grantedPermissions);
} catch (e) {}
const chechAccess = key => {
  return grantedPermissions && grantedPermissions[key] === "true";
};

const App = () => {
  const resources = [];
  if (chechAccess("Roles")) {
    resources.push(
      <Resource
        key={"roles"}
        name="roles"
        create={RoleCreate}
        edit={RoleEdit}
        list={RolesList}
        icon={PermIdentityIcon}
        options={{ label: "Роли" }}
      />
    );
  }
  if (chechAccess("Users")) {
    resources.push(
      <Resource
        key={"users"}
        name="users"
        create={UserCreate}
        edit={UserEdit}
        list={UsersList}
        options={{ label: "Пользователи" }}
        icon={PeopleIcon}
      />
    );
  }
  if (chechAccess("ServicePoints")) {
    resources.push(
      <Resource
        key={"servicePoints"}
        name="servicePoints"
        create={ServicePointsCreate}
        edit={ServicePointsEdit}
        list={ServicePointsList}
        icon={LocationIcon}
        options={{ label: "Сервисные пункты" }}
      />
    );
  }
  if (chechAccess("Vacancies")) {
    resources.push(
      <Resource
        key={"vacancy"}
        name="vacancy"
        create={VacancyCreate}
        edit={VacancyEdit}
        list={VacancyList}
        icon={WorkIcon}
        options={{ label: "Вакансии" }}
      />
    );
  }
  if (chechAccess("Articles")) {
    resources.push(
      <Resource
        key={"articles"}
        name="articles"
        create={ArticlesCreate}
        edit={ArticlesEdit}
        list={ArticlesList}
        options={{ label: "Статьи / новости" }}
        icon={NoteIcon}
      />
    );
  }
  if (chechAccess("Faqs")) {
    resources.push(
      <Resource
        key={"faq"}
        name="faq"
        create={FAQCreate}
        edit={FAQEdit}
        list={FAQList}
        icon={QuestionAnswerIcon}
        options={{ label: "FAQ" }}
      />
    );
  }
  if (chechAccess("Tickets")) {
    resources.push(
      <Resource
        key={"tickets"}
        name="tickets"
        list={TicketsList}
        show={TicketsShow}
        icon={ReceiptIcon}
        options={{ label: "Заявки" }}
      />
    );
  }
  if (chechAccess("Measurements")) {
    resources.push(
      <Resource
        name="measurements"
        list={Measurements}
        options={{ label: "Показания" }}
      />
    );
  }
  if (chechAccess("Payments")) {
    resources.push(
      <Resource
        name="payments"
        list={Payments}
        options={{ label: "Платежи" }}
      />
    );
    resources.push(
      <Resource
        name="payment-accounts"
        list={PaymentAccounts}
        options={{ label: "Платёжные аккаунты" }}
      />
    );
  }
  
  return (
    <Admin
      appLayout={MainLayout}
      authProvider={authProvider}
      customRoutes={[
        <Route key="profile" path="/profile" component={ProfileEdit} />
      ]}
      dataProvider={dataProvider}
      locale="ru"
      i18nProvider={i18nProvider}
    >
      {resources}
      <Resource
        key={"files"}
        name="files"
        list={FilesList}
        icon={FileIcon}
        options={{ label: "Файлы" }}
      />
      <Resource name="city" />
      <Resource name="contacts" create={ContactsCreate} edit={ContactsEdit} />
      <Resource name="permissions" />
      <Resource name="profile" />
      <Resource name="rolesList" />
      <Resource name="servicePointsList" />
      <Resource
        name="schedules"
        create={SchedulesCreate}
        edit={SchedulesEdit}
      />
      <Resource name="tags" />
      <Resource name="vacancyType" />
    </Admin>
  );
};

export default App;
