import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput
} from "react-admin";

import { connect } from "react-redux";
import compose from "recompose/compose";
import { push } from "react-router-redux";

import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  button: {
    marginTop: "1em"
  }
};

const AddNewContactButton = withStyles(styles)(({ classes, record }) => (
  <Button
    className={classes.button}
    component={Link}
    to={{
      pathname: "/contacts/create",
      search: `?pointId=${record.id}`
    }}
    label="Добавить контакт"
  >
    <AddIcon />
  </Button>
));

const AddNewSchedulestButton = withStyles(styles)(({ classes, record }) => (
  <Button
    className={classes.button}
    component={Link}
    to={{
      pathname: "/schedules/create",
      search: `?pointId=${record.id}`
    }}
    label="Добавить расписание"
  >
    <AddIcon />
  </Button>
));

class ServicePointsEdit extends React.Component {
  handleClose = () => {
    this.props.push("/servicePoints/" + this.props.id + "/1");
  };

  render() {
    const { push, classes, ...props } = this.props;
    return (
      <div>
        <Edit title="Редактирование" {...props}>
          <TabbedForm>
            <FormTab label="Основная информация">
              <TextInput source="name" label="Название" />
              <TextInput source="description" label="Описание" />
              <ReferenceInput
                label="Город"
                source="address.city.id"
                reference="city"
              >
                <SelectInput optionText="value" />
              </ReferenceInput>
              <TextInput source="address.address1" label="address1" />
              <TextInput source="address.address2" label="address2" />
              <TextInput source="address.zipCode" label="Индекс" />
              <NumberInput source="lat" label="lat" validate={required()} />
              <NumberInput source="lng" label="lng" validate={required()} />
              <ReferenceArrayInput source="tags" reference="tags" label="Теги">
                <SelectArrayInput optionText="name" />
              </ReferenceArrayInput>
            </FormTab>
            <FormTab label="Контакты">
              <ReferenceManyField
                reference="contacts"
                target="id"
                addLabel={false}
              >
                <Datagrid>
                  <TextField source="name" label="Название" />
                  <TextField source="email" />
                  <TextField source="phone" label="Телефон" />
                  <EditButton label="Редактировать" />
                  <DeleteButton
                    label="Удалить"
                    redirect={"/servicePoints/" + this.props.id + "/1"}
                  />
                </Datagrid>
              </ReferenceManyField>

              <AddNewContactButton />
            </FormTab>
            <FormTab label="Расписание">
              <ReferenceManyField
                reference="schedules"
                target="id"
                addLabel={false}
              >
                <Datagrid>
                  <TextField source="name" label="Название" />
                  <EditButton label="Редактировать" />
                  <DeleteButton
                    label="Удалить"
                    redirect={"/servicePoints/" + this.props.id + "/2"}
                  />
                </Datagrid>
              </ReferenceManyField>
              <AddNewSchedulestButton />
            </FormTab>
          </TabbedForm>
        </Edit>
      </div>
    );
  }
}

export default compose(
  connect(
    undefined,
    { push }
  ),
  withStyles(styles)
)(ServicePointsEdit);
