import React from "react";
import {
  Create,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput
} from "react-admin";

export const ServicePointsCreate = props => (
  <Create title="Новый сервисный пункт" {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" label="Название" validate={required()} />
      <TextInput source="description" label="Описание" />
      <ReferenceInput label="Город" source="address.cityId" reference="city">
        <SelectInput optionText="value" />
      </ReferenceInput>
      <TextInput source="address.address1" label="address.address1" />
      <TextInput source="address.address2" label="address.address2" />
      <TextInput source="address.zipCode" label="address.zipCode" />
      <NumberInput source="lat" label="lat" validate={required()} />
      <NumberInput source="lng" label="lng" validate={required()} />
      <ReferenceArrayInput source="tagIds" reference="tags" label="Теги">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
