import React from "react";
import apiUrl from "../providers/apiUrl";

export const FilesListFrame = () => (
  <iframe
    style={{
      border: "none",
      margin: "-24px -24px -24px -5px",
      height: "calc(100vh - 48px)"
    }}
    title="Файлы"
    src={
      `${apiUrl}/s3explorer?enc_auth_token=` +
      encodeURIComponent(localStorage.getItem("token"))
    }
  />
);

export const FilesList = () => <FilesListFrame />;
