import React from "react";
import { Create, NumberInput, required, SimpleForm } from "react-admin";

import RichTextInput from "ra-input-rich-text";

export const FAQCreate = props => (
  <Create title="Новый вопрос-ответ" {...props}>
    <SimpleForm redirect="list">
      <RichTextInput source="question" label="Вопрос" validate={required()} />
      <RichTextInput source="answer" label="Ответ" validate={required()} />
      <NumberInput source="order" label="Порядковый номер" />
    </SimpleForm>
  </Create>
);
