import React from "react";
import {
  BooleanInput,
  Edit,
  email,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar
} from "react-admin";

const phoneValidation = (value, allValues) => {
  const isRequired = false;
  let isEmptyValue = !value;
  if (!isEmptyValue) {
    isEmptyValue = value === "";
  }
  const validationMask = /^[0-9()\-+\s]+$/;
  let isValid = (!isRequired && isEmptyValue) || validationMask.test(value);
  if (!isValid) {
    return "Проверьте введённый номер телефона";
  }
  return;
};

const contactsSeparator = "-";

const ContactsCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const ContactsEdit = props => {
  const ids = props.id.split(contactsSeparator);
  const redirect = `/servicePoints/${ids[1]}/1`;
  return (
    <Edit title="Редактировать контакт" {...props}>
      <SimpleForm redirect={redirect} toolbar={<ContactsCreateToolbar />}>
        <ReferenceInput
          label="Сервисный пункт"
          source="servicePointId"
          reference="servicePointsList"
        >
          <SelectInput optionText="label" disabled />
        </ReferenceInput>
        <TextInput source="name" label="Название" />
        <TextInput source="email" label="Email" validate={[email()]} />
        <TextInput source="phone" label="Телефон" validate={phoneValidation} />
        <BooleanInput label="Viber" source="hasViber" defaultValue={false} />
        <BooleanInput
          label="WhatsUp"
          source="hasWhatsUp"
          defaultValue={false}
        />
      </SimpleForm>
    </Edit>
  );
};
