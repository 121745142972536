import React, { useState, useCallback } from "react";
import { Button } from "@material-ui/core";
import apiUrl from "../providers/apiUrl";

const ImportButton = () => {
  const [importing, setImporting] = useState(false);

  const handleFileUpload = useCallback(async e => {
    setImporting(true);

    try {
      e.persist();

      if (e.target.files.length !== 1) {
        throw new Error("Вы должны выбрать один файл");
      }

      const [file] = e.target.files;

      if (
        file.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        throw new Error("Неверный формат файла");
      }

      var body = new FormData();
      body.append("file", file);

      await fetch(`${apiUrl}/api/services/app/Measurement/UploadDevices`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        },
        body
      });

      window.location.reload();
    } catch (error) {
      alert(error.message);
    } finally {
      setImporting(false);
    }
  }, []);

  return (
    <>
      <input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        id="import-file-button"
        type="file"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
      <label htmlFor="import-file-button">
        <Button color="primary" component="span" disabled={importing}>
          {importing ? "Пожалуйста подождите ..." : "Импорт"}
        </Button>
      </label>
    </>
  );
};

export default ImportButton;
