import React, { Component } from "react";
import { connect } from "react-redux";
import { crudGetOne, UserMenu, MenuItemLink } from "react-admin";

import LockIcon from "@material-ui/icons/Lock";

class MainUserMenuView extends Component {
  componentDidMount() {
    // this.fetchProfile();
  }

  fetchProfile = () => {
    this.props.crudGetOne(
      // The resource
      "profile",
      // The id of the resource item to fetch
      "profile",
      // The base path. Mainly used on failure to fetch the data
      "/profile",
      // Whether to refresh the current view. I don't need it here
      false
    );
  };

  render() {
    const { crudGetOne, profile, ...props } = this.props;

    return (
      <UserMenu label="" {...props}>
        <MenuItemLink
          to="/profile"
          primaryText="Изменить пароль"
          leftIcon={<LockIcon />}
        />
      </UserMenu>
    );
  }
}

const mapStateToProps = state => {
  const resource = "profile";
  const id = "profile";
  const profileState = state.admin.resources[resource];
  return {
    profile: profileState ? profileState.data[id] : null
  };
};

const MainUserMenu = connect(
  mapStateToProps,
  { crudGetOne }
)(MainUserMenuView);
export default MainUserMenu;
