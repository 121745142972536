import React from "react";
import {
  CardActions,
  CreateButton,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField
} from "react-admin";

import { TagsField } from "./tags";

const ServicePointsActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);

export const ServicePointsList = props => (
  <List
    {...props}
    title="Сервисные пункты"
    actions={<ServicePointsActions />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="address.city.value" label="Город" />
      <TextField source="address.address1" label="Адрес" />
      <TagsField label="Теги" />
      <EditButton label="Редактировать" />
      <DeleteButton label="Удалить" />
    </Datagrid>
  </List>
);
