import React from "react";
import {
  BooleanInput,
  Create,
  email,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar
} from "react-admin";

import { parse } from "query-string";

const phoneValidation = (value, allValues) => {
  const isRequired = false;
  let isEmptyValue = !value;
  if (!isEmptyValue) {
    isEmptyValue = value === "";
  }
  const validationMask = /^[0-9()\-+\s]+$/;
  let isValid = (!isRequired && isEmptyValue) || validationMask.test(value);
  if (!isValid) {
    return "Проверьте введённый номер телефона";
  }
  return;
};

const ContactsCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const ContactsCreate = props => {
  const { pointId: pointIdString } = parse(props.location.search);
  const pointId = pointIdString ? parseInt(pointIdString, 10) : "";
  const redirect = pointId ? `/servicePoints/${pointId}/1` : "/servicePoints";
  return (
    <Create title="Новый контакт" {...props}>
      <SimpleForm
        defaultValue={{ servicePointId: pointId }}
        redirect={redirect}
        toolbar={<ContactsCreateToolbar />}
      >
        <ReferenceInput
          label="Сервисный пункт"
          source="servicePointId"
          reference="servicePointsList"
        >
          <SelectInput optionText="label" disabled />
        </ReferenceInput>
        <TextInput source="name" label="Название" />
        <TextInput source="email" label="Email" validate={[email()]} />
        <TextInput source="phone" label="Телефон" validate={phoneValidation} />
        <BooleanInput label="Viber" source="hasViber" defaultValue={false} />
        <BooleanInput
          label="WhatsUp"
          source="hasWhatsUp"
          defaultValue={false}
        />
      </SimpleForm>
    </Create>
  );
};
