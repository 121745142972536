import React, { useCallback, useState } from "react";
import {
  Datagrid,
  List,
  TextField,
  DateField,
  FunctionField,
  ShowButton,
  Button,
  Filter,
  SelectInput
} from "react-admin";
import { renderType } from "./helpers";
import IconCheck from "@material-ui/icons/Check";
import dataProvider from "../providers/data_provider";

const TicketsFilter = props => (
  <Filter {...props}>
    <SelectInput
      label="Фильтр по типу"
      source="type"
      alwaysOn
      choices={[
        { id: "0", name: "Поддержка" },
        { id: "1", name: "От провайдера" },
        { id: "2", name: "Отклик на вакансию" },
        { id: "3", name: "Смена количества проживающих ТКО" }
      ]}
    />
  </Filter>
);

const ApproveButton = props => {
  const { record: { id, approved } = {} } = props;
  const [approvedLocal, setApprovedLocal] = useState(approved);

  const handleClick = useCallback(async () => {
    await dataProvider("APPROVE_TICKET", "ticket", { id });
    setApprovedLocal(true);
  }, [id]);

  return (
    <>
      {approvedLocal ? null : (
        <Button {...props} onClick={handleClick}>
          <IconCheck />
        </Button>
      )}
    </>
  );
};

export const TicketsList = props => {
  return (
    <List
      {...props}
      filters={<TicketsFilter />}
      title="Заявки"
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="id" label="Номер заявки" sortable={false} />
        <TextField source="name" label="Имя" sortable={false} />
        <TextField source="phone" label="Телефон" sortable={false} />
        <TextField source="email" label="Электронная почта" sortable={false} />
        <FunctionField
          source="type"
          label="Тип заявки"
          sortable={false}
          render={renderType}
        />
        <DateField
          source="creationTime"
          label="Дата создания"
          locales="ru-RU"
          sortable={false}
          showTime={true}
        />
        <ShowButton label="Открыть" />
        <ApproveButton label="Подтвердить" />
      </Datagrid>
    </List>
  );
};
