import React from "react";
import {
  Edit,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar
} from "react-admin";

import { Daysgrid } from "./daysgrid";

const schedulesSeparator = "-";

const SchedulesCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const SchedulesEdit = props => {
  const ids = props.id.split(schedulesSeparator);
  const redirect = `/servicePoints/${ids[1]}/2`;
  return (
    <Edit title="Редактировать расписание" {...props}>
      <SimpleForm redirect={redirect} toolbar={<SchedulesCreateToolbar />}>
        <ReferenceInput
          label="Сервисный пункт"
          source="servicePointId"
          reference="servicePointsList"
        >
          <SelectInput optionText="label" disabled />
        </ReferenceInput>
        <TextInput source="name" label="Название" />
        <Daysgrid />
        <NumberInput source="order" label="Порядковый номер" />
      </SimpleForm>
    </Edit>
  );
};
