import React from "react";

export const PermissionsField = ({ record }) => {
  return record.permissions ? (
    <ul>
      {record.permissions.map((item, i) => (
        <li key={i}>{item}</li>
      ))}
    </ul>
  ) : null;
};
PermissionsField.defaultProps = { addLabel: true };
