import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  FunctionField
} from "react-admin";
import { renderType } from "./helpers";

const TicketTitle = ({ record }) => {
  return <span>Заявка #{record ? record.id : ""}</span>;
};

const FileList = ({ record: { files = [] } = {} }) => {
  return (
    <ul>
      {files.map(url => (
        <li>
          <a href={url} target="__blank">
            {url}
          </a>
        </li>
      ))}
    </ul>
  );
};

export const TicketsShow = props => {
  return (
    <Show title={<TicketTitle />} {...props}>
      <SimpleShowLayout>
        <FunctionField source="type" label="Тип заявки" render={renderType} />
        <TextField source="id" label="Номер заявки" />
        <TextField source="name" label="Имя" />
        <TextField source="phone" label="Телефон" />
        <TextField source="email" label="Электронная почта" />
        <TextField source="inn" label="Количество проживающих" />
        <TextField source="vacancy.id" label="Вакансия" />
        <TextField source="vacancy.name" label="Наименование вакансии" />
        <TextField source="message" label="Сообщение" />
        <TextField source="address.city" label="Город" />
        <TextField source="address.street" label="Улица" />
        <TextField source="address.building" label="Номер дома" />
        <TextField source="address.apartment" label="Номер квартиры" />
        <TextField source="apartmentArea" label="Площадь" />
        <TextField source="residentQuantity" label="Количество проживающих" />
        <FileList addLabel={true} label="Файлы" />
        <DateField
          source="creationTime"
          locales="ru-RU"
          label="Дата создания"
          showTime={true}
        />
      </SimpleShowLayout>
    </Show>
  );
};
