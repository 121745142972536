import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from "react-admin";
import { has } from "lodash";
import apiUrl from "./apiUrl";

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(`${apiUrl}/api/TokenAuth/Authenticate`, {
      method: "POST",
      body: JSON.stringify({ userNameOrEmailAddress: username, password }),
      headers: new Headers({ "Content-Type": "application/json" })
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
        localStorage.setItem("token", data.result.encryptedAccessToken);
        localStorage.setItem("accessToken", data.result.accessToken);
        localStorage.setItem("userId", data.result.userId);
        fetch(
          new Request(`${apiUrl}/AbpUserConfiguration/GetAll`, {
            method: "GET",
            headers: new Headers({
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              "Content-Type": "application/json"
            })
          })
        )
          .then(response => {
            if (response.status < 200 || response.status >= 300) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then(data => {
            const grantedPermissions = has(
              data,
              "result.auth.grantedPermissions"
            )
              ? data.result.auth.grantedPermissions
              : null;
            localStorage.setItem(
              "grantedPermissions",
              grantedPermissions
                ? JSON.stringify(grantedPermissions)
                : grantedPermissions
            );
            const urlMap = {
              Roles: "/#/roles",
              Users: "/#/users",
              ServicePoints: "/#/servicePoints",
              Vacancies: "/#/vacancy",
              Articles: "/#/articles",
              Faqs: "/#/faq",
              Tickets: "/#/tickets"
            };
            const moduleKey = Object.keys(urlMap).find(
              key => grantedPermissions && grantedPermissions[key] === "true"
            );
            if (urlMap[moduleKey]) {
              window.location.href = urlMap[moduleKey];
            }
            window.location.reload(false);
          });
      });
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("token");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("grantedPermissions");
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("grantedPermissions");
      return Promise.reject();
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  }
  return Promise.reject("Unknown method");
};
