import React from "react";
import {
  CardActions,
  CreateButton,
  //   ChipField,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  //   ReferenceManyField,
  //   SingleFieldList,
  TextField
} from "react-admin";

import { PermissionsField } from "./permissions";

const RolesActions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter
}) => (
  <CardActions>
    {/* {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })} */}
    <CreateButton basePath={basePath} />
  </CardActions>
);

export const RolesList = props => (
  <List
    {...props}
    title="Роли"
    actions={<RolesActions />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="displayName" label="Название" />
      <TextField source="description" label="Описание" />
      <PermissionsField label="Разрешения" />
      <EditButton label="Редактировать" />
      <DeleteButton label="Удалить" />
    </Datagrid>
  </List>
);
