import React, { useCallback, useState } from "react";
import { Datagrid, List, TextField, CardActions } from "react-admin";
import { Button } from "@material-ui/core";
import apiUrl from "../providers/apiUrl";

const ImportButton = () => {
  const [importing, setImporting] = useState(false);

  const handleFileUpload = useCallback(async e => {
    setImporting(true);

    try {
      e.persist();

      if (e.target.files.length !== 1) {
        throw new Error("Вы должны выбрать один файл");
      }

      const [file] = e.target.files;

      if (
        file.type !== "application/zip" &&
        file.type !== "application/x-zip-compressed"
      ) {
        throw new Error("Неверный формат файла");
      }

      var body = new FormData();
      body.append("file", file);

      const response = await fetch(
        `${apiUrl}/api/services/app/Payment/UploadAccounts`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          },
          body
        }
      );

      const { error } = (await response.json()) || {};
      if (error) {
        throw error;
      } else {
        window.location.reload();
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setImporting(false);
    }
  }, []);

  return (
    <>
      <input
        accept="application/zip"
        id="import-file-button"
        type="file"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
      <label htmlFor="import-file-button">
        <Button color="primary" component="span" disabled={importing}>
          {importing ? "Пожалуйста подождите ..." : "Импорт"}
        </Button>
      </label>
    </>
  );
};

const Actions = () => {
  return (
    <CardActions>
      <ImportButton />
    </CardActions>
  );
};

const PaymentAccountsList = props => (
  <List
    {...props}
    title="Платёжные аккаунты"
    bulkActionButtons={false}
    actions={<Actions />}
  >
    <Datagrid>
      <TextField source="clientId" label="Номер клиента" sortable={false} />
      <TextField source="address" label="Адрес" sortable={false} />
      <TextField source="amount" label="Задолженность" sortable={false} />
      <TextField source="purpose" label="Назначение" sortable={false} />
    </Datagrid>
  </List>
);

export default PaymentAccountsList;
