import React, { Component, Fragment } from "react";
import {
  Button,
  fetchEnd,
  fetchStart,
  required,
  resetForm,
  SaveButton,
  SimpleForm,
  showNotification,
  TextInput,
  UPDATE
} from "react-admin";
import { connect } from "react-redux";
import { change, isSubmitting, reset, submit } from "redux-form";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import IconCancel from "@material-ui/icons/Cancel";
import IconLock from "@material-ui/icons/Lock";

import dataProvider from "../providers/data_provider";

const formId = "change-user-password";

class UsersPassword extends Component {
  state = {
    error: false,
    showDialog: false
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    const { reset, resetForm } = this.props;
    resetForm(formId);
    reset(formId);
    this.setState({ showDialog: false });
  };

  handleSaveClick = () => {
    const { submit } = this.props;

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit(formId);
  };

  handleSubmit = values => {
    const {
      fetchStart,
      fetchEnd,
      reset,
      resetForm,
      showNotification
    } = this.props;

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(UPDATE, "userPassword", {
      data: { ...values, ...{ userId: this.props.user.id } }
    })
      .then(({ data }) => {
        resetForm(formId);
        reset(formId);
        this.setState({ showDialog: false });
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  render() {
    const { showDialog } = this.state;
    const { isSubmitting } = this.props;
    return (
      <Fragment>
        <Button onClick={this.handleClick} label="Изменить пароль">
          <IconLock />
        </Button>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick}>
          <DialogTitle>Изменить пароль</DialogTitle>
          <DialogContent>
            <SimpleForm
              // We override the redux-form name to avoid collision with the react-admin main form
              form={formId}
              // We override the redux-form onSubmit prop to handle the submission ourselves
              onSubmit={this.handleSubmit}
              // We want no toolbar at all as we have our modal actions
              toolbar={null}
            >
              <TextInput
                source="adminPassword"
                label="Пароль администратора"
                type="password"
                validate={required()}
              />
              <TextInput
                source="newPassword"
                label="Новый пароль пользователя"
                type="password"
                validate={required()}
              />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting(formId)(state)
});

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  reset,
  resetForm,
  showNotification,
  submit
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersPassword);
