import React from "react";
import {
  CardActions,
  CreateButton,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField
} from "react-admin";

const VacancyActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);

export const VacancyList = props => (
  <List
    {...props}
    title="Вакансии"
    actions={<VacancyActions />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="name" label="Название" />
      <TextField source="salary" label="Зарплата" />
      <TextField source="type.value" label="Тип" />
      <TextField source="servicePoint.name" label="Сервисный пункт" />
      <TextField source="servicePoint.address.city.value" label="Город" />
      <TextField source="servicePoint.address.address1" label="Адрес" />
      <TextField source="workExperience" label="Опыт работы" />
      <EditButton label="Редактировать" />
      <DeleteButton label="Удалить" />
    </Datagrid>
  </List>
);
