import React from "react";
import {
  BooleanInput,
  Create,
  email,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SimpleForm,
  TextInput
} from "react-admin";

export const UserCreate = props => (
  <Create title="Новый пользователь" {...props}>
    <SimpleForm redirect="list">
      <TextInput source="userName" label="Логин" validate={required()} />
      <TextInput
        label="Пароль"
        source="password"
        type="password"
        validate={required()}
      />
      <TextInput source="name" label="Имя" validate={required()} />
      <TextInput source="surname" label="Фамилия" validate={required()} />
      <TextInput
        source="emailAddress"
        label="Email"
        validate={[required(), email()]}
      />
      <ReferenceArrayInput
        source="roleNames"
        reference="rolesList"
        label="Роли"
        validate={required()}
      >
        <SelectArrayInput optionText="displayName" />
      </ReferenceArrayInput>
      <BooleanInput label="Активен" source="isActive" defaultValue />
    </SimpleForm>
  </Create>
);
