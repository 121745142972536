import React, { useState, useCallback } from "react";
import { Button } from "@material-ui/core";
import apiUrl from "../providers/apiUrl";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from "@material-ui/core";
import { stringify } from "query-string";

const defaultFrom = new Date();
defaultFrom.setMonth(defaultFrom.getMonth() - 1);

const defaultTo = new Date();

const ImportButton = () => {
  const [loading, setLoading] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [fromDate, setFromDate] = useState(
    defaultFrom.toISOString().substr(0, 10)
  );
  const [toDate, setToDate] = useState(defaultTo.toISOString().substr(0, 10));
  const showDialog = useCallback(() => setDialogIsOpen(true), []);
  const hideDialog = useCallback(() => setDialogIsOpen(false), []);

  const handleFromDateChange = useCallback(e => {
    e.persist();

    setFromDate(e.target.value);
  }, []);

  const handleToDateChange = useCallback(e => {
    e.persist();

    setToDate(e.target.value);
  }, []);

  const handleExport = useCallback(
    async e => {
      setLoading(true);

      try {
        const authentication = JSON.parse(
          localStorage.getItem("authentication")
        );

        window.open(
          `${apiUrl}/api/services/app/Measurement/ExportAsync?${stringify({
            from: fromDate,
            to: toDate,
            enc_auth_token: encodeURIComponent(
              authentication.encryptedAccessToken
            )
          })}`,
          "__blank"
        );
      } catch (error) {
        alert(error.message);
      } finally {
        setLoading(false);
      }
    },
    [fromDate, toDate]
  );

  return (
    <>
      <Button
        color="primary"
        component="span"
        disabled={loading}
        onClick={showDialog}
      >
        {loading ? "Пожалуйста подождите ..." : "Экспорт"}
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={dialogIsOpen}
        onClose={hideDialog}
      >
        <DialogTitle>Выберите даты</DialogTitle>
        <DialogContent>
          <TextField
            label="Дата начала"
            type="date"
            value={fromDate}
            fullWidth={true}
            InputLabelProps={{
              shrink: true
            }}
            style={{ marginBottom: "20px" }}
            max={toDate}
            onChange={handleFromDateChange}
          />
          <TextField
            label="Дата окончания"
            type="date"
            value={toDate}
            fullWidth={true}
            InputLabelProps={{
              shrink: true
            }}
            min={fromDate}
            onChange={handleToDateChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={hideDialog}>Отмена</Button>
          <Button variant="contained" color="primary" onClick={handleExport}>
            Экспортировать
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImportButton;
