import React, { useMemo } from "react";
import {
  Datagrid,
  List,
  TextField,
  BooleanField,
  DateField,
  CardActions
} from "react-admin";
import ImportButton from "./import-button";
import ExportButton from "./export-button";

const Actions = () => {
  return (
    <CardActions>
      <ExportButton />
      <ImportButton />
    </CardActions>
  );
};

const MeasurementDetails = ({ record }) => {
  const data = useMemo(
    () =>
      record.measurements.reduce(
        (obj, item) => ((obj[item.code] = item), obj),
        {}
      ),
    [record.measurements]
  );

  const ids = useMemo(() => record.measurements.map(x => x.code), [
    record.measurements
  ]);

  return (
    <Datagrid currentSort={{}} data={data} ids={ids}>
      <TextField source="code" label="Код отправки" sortable={false} />
      <TextField source="address" label="Адрес" sortable={false} />
      <TextField source="serviceName" label="Сервис" sortable={false} />
      <TextField
        source="deviceSerialNumber"
        label="Номер устройства"
        sortable={false}
      />
      <TextField
        source="deviceModel"
        label="Модель устройства"
        sortable={false}
      />
      <DateField source="date" label="Дата" sortable={false} />
      <TextField source="value" label="Показание" sortable={false} />
    </Datagrid>
  );
};

const MeasurementsList = props => (
  <List
    {...props}
    title="Показания"
    bulkActionButtons={false}
    actions={<Actions />}
  >
    <Datagrid expand={<MeasurementDetails />}>
      <TextField source="clientId" label="Номер клиента" sortable={false} />
      <TextField source="invoiceId" label="Номер квитанции" sortable={false} />
      <BooleanField source="isLocal" label="Локальный" sortable={false} />
      <BooleanField source="isCompleted" label="Заершен" sortable={false} />
    </Datagrid>
  </List>
);

export default MeasurementsList;
