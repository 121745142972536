import React from "react";
import {
  Create,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar
} from "react-admin";

import { parse } from "query-string";

import { Daysgrid } from "./daysgrid";

const SchedulesCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const SchedulesCreate = props => {
  const { pointId: pointIdString } = parse(props.location.search);
  const pointId = pointIdString ? parseInt(pointIdString, 10) : "";
  const redirect = pointId ? `/servicePoints/${pointId}/2` : "/servicePoints";
  return (
    <Create title="Новое расписание" {...props}>
      <SimpleForm
        defaultValue={{ servicePointId: pointId }}
        redirect={redirect}
        toolbar={<SchedulesCreateToolbar />}
      >
        <ReferenceInput
          label="Сервисный пункт"
          source="servicePointId"
          reference="servicePointsList"
        >
          <SelectInput optionText="label" disabled />
        </ReferenceInput>
        <TextInput source="name" label="Название" />
        <Daysgrid />
        <NumberInput source="order" label="Порядковый номер" />
      </SimpleForm>
    </Create>
  );
};
